import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import { Skeleton } from '@material-ui/lab';

import UpdateLoyaltyMediaForm from 'components/loyalty-card/update-create-media';

import { ALL_CARDS_ROUTE } from 'constants/navigation';
import { forceWait } from 'apputil/retry-promise';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import getDetailedPassService from 'services/passes/get-detailed-pass-service';

import { Grid } from '@material-ui/core';

import useStyles from './styles';

const UpdateMediaView = ({ passId }) => {
  const [loading, setLoading] = useState(true);
  const [heldPass, setHeldPass] = useState({});
  const [savedForm, setSavedForm] = useState({});

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();
  const { getDetailedPassById } = getDetailedPassService({ stateContext, noticationContext });

  const styles = useStyles();

  useEffect(() => {
    const getSavedFormData = async () => {
      const passData = await getDetailedPassById({ passId });
      const {
        image = {},
        hexBackgroundColor = '', // HEX
        foregroundColor = '', // rgb
        backgroundColor = '', // rgb
      } = passData || {};
      const { url } = image;
      setSavedForm({
        image: url,
        hexBackgroundColor,
        foregroundColor,
        backgroundColor,
      });
      setLoading(false);
      setHeldPass(passData);
    };

    getSavedFormData();

    // eslint-disable-next-line
  }, []);

  const { name, organization } = heldPass;

  return loading ? (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
    <Grid item xs={12} sm={12} md={10} xl={8}>
      <Skeleton
        animation="wave"
        component="div"
        height={750}
        className={styles.mediaSkeleton}
        variant="rect"
        />
      </Grid>
    </Grid>
  ) : (<UpdateLoyaltyMediaForm
            savedForm={savedForm}
            pass={{ passId, name: name || organization }}
            saveForm={async (data) => {
              const getUpdatePassService = await import('services/passes/update-pass-service');
              const { updateLoyaltyPass } = getUpdatePassService.default({
                stateContext,
                noticationContext,
              });
              const pass = {
                passId,
                ...heldPass,
                ...data,
              };
              await updateLoyaltyPass({ pass });
              navigate(ALL_CARDS_ROUTE);
              await forceWait({ time: 100 });
            }}/>);
};

UpdateMediaView.propTypes = {
  passId: string.isRequired,
};

export default UpdateMediaView;
