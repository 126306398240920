import React from 'react';
import { func, object } from 'prop-types';

import LoyaltyMediaForm from 'components/loyalty-card/create-media';

const UpdateLoyaltyInformation = ({ savedForm, saveForm, pass }) => (
    <LoyaltyMediaForm saveForm={saveForm} savedForm={savedForm} pass={pass}/>
);

UpdateLoyaltyInformation.propTypes = {
  saveForm: func.isRequired,
  savedForm: object,
  pass: object.isRequired,
};

export default UpdateLoyaltyInformation;
